import { useSession } from "next-auth/client";
import { useRouter } from "next/router";
import Link from "next/link";
import { Badge } from "react-bootstrap";
export default function Header() {
  const [session, loading] = useSession();
  const router = useRouter();

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-sm navbar-light bg-white">
          <div className="container">
            <Link href="/">
              <a className="navbar-brand d-flex align-items-center">
                <img
                  src="/assets/images/dmb-canvas-logo.png"
                  alt="logo"
                  className="img-fluid"
                  width="30px"
                />
                <span className="h5 m-0 pl-1 font-weight-normal">
                  DMB Canvass
                </span>
              </a>
            </Link>

            <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavId"
              aria-controls="collapsibleNavId"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavId">
              <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                {session.role !== "Data-Entry-Officer" && (
                  <>
                    {router.asPath === "/" ||
                    router.asPath.includes("meter-details") ||
                    router.asPath.includes("dashboard") ? (
                      <li className="nav-item active">
                        <div className="dropdown">
                          <Link href="/">
                            <a className="nav-link">Dashboard</a>
                          </Link>

                          {/* <div className="dropdown-content">
                            <Link href="/expanded-delegates">
                              <a>Expanded Delegates</a>
                            </Link>
                            <Link
                              target="_blank"
                              href="https://forms.gle/KNgev6uf658yLohd6"
                            >
                              <a>Customer Care</a>
                            </Link>
                          </div> */}
                        </div>
                      </li>
                    ) : (
                      <li className="nav-item">
                        <div className="dropdown">
                          <Link href="/">
                            <a className="nav-link">Dashboard</a>
                          </Link>

                          <div className="dropdown-content">
                            <Link href="/expanded-delegates">
                              <a>Expanded Delegates</a>
                            </Link>
                            <Link
                              target="_blank"
                              href="https://forms.gle/KNgev6uf658yLohd6"
                            >
                              <a>Customer Care</a>
                            </Link>
                          </div>
                        </div>
                      </li>
                    )}
                  </>
                )}
                {router.asPath.includes("contact") ||
                router.asPath.includes("groups") ? (
                  <li className="nav-item active">
                    <div className="dropdown">
                      <Link href="/contacts">
                        <a className="nav-link">Contacts</a>
                      </Link>

                      <div className="dropdown-content">
                        <Link href="https://analysis.dmb2024.com/">
                          <a target="_blank">Analysis</a>
                        </Link>
                        <Link href="/groups">
                          <a>Groups</a>
                        </Link>
                        <Link href="/allegiance-stats">
                          <a>Allegiance Breakdown</a>
                        </Link>
                        <Link href="/allegiance-histories">
                          <a>Allegiance Histories</a>
                        </Link>
                        <Link href="/dnn">
                          <a>DNN Allegiance</a>
                        </Link>
                        <Link href="/birthdays">
                          <a>Birthdays</a>
                        </Link>
                        <Link href="/downloads">
                          <a>Downloads</a>
                        </Link>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item">
                    <div className="dropdown">
                      <Link href="/contacts">
                        <a className="nav-link">Contacts</a>
                      </Link>

                      <div className="dropdown-content">
                        <Link href="https://analysis.dmb2024.com/">
                          <a target="_blank">Analysis</a>
                        </Link>
                        <Link href="/groups">
                          <a>Groups</a>
                        </Link>
                        <Link href="/allegiance-stats">
                          <a>Allegiance Breakdown</a>
                        </Link>
                        <Link href="/allegiance-histories">
                          <a>Allegiance Histories</a>
                        </Link>
                        <Link href="/dnn">
                          <a>DNN Allegiance</a>
                        </Link>
                        <Link href="/birthdays">
                          <a>Birthdays</a>
                        </Link>
                        <Link href="/downloads">
                          <a>Downloads</a>
                        </Link>
                      </div>
                    </div>
                  </li>
                )}

                {router.asPath.includes("/voter-analysis") ? (
                  <li className="nav-item active">
                    <div className="dropdown">
                      <Link href="/voter-analysis">
                        <a className="nav-link">Voter Analysis</a>
                      </Link>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item">
                    <div className="dropdown">
                      <Link href="/voter-analysis">
                        <a className="nav-link">Voter Analysis</a>
                      </Link>
                    </div>
                  </li>
                )}

                {session.role !== "Data-Entry-Officer" && (
                  <>
                    <li
                      className={`nav-item ${
                        (router.asPath.includes("event") ||
                          router.asPath.includes("promises") ||
                          router.asPath.includes("tasks") ||
                          router.asPath.includes("notes") ||
                          router.asPath.includes("add-canvass") ||
                          router.asPath.includes("canvass") ||
                          router.asPath.includes("survey")) &&
                        "active"
                      }`}
                    >
                      <div className="dropdown">
                        <Link href="/events">
                          <a className="nav-link">Events </a>
                        </Link>

                        <div className="dropdown-content">
                          <Link href="/notes">
                            <a>Notes</a>
                          </Link>
                          <Link href="/all-tasks">
                            <a>Tasks</a>
                          </Link>
                          <Link href="/promises">
                            <a>Promises</a>
                          </Link>
                          <Link href="/canvass">
                            <a>Canvass</a>
                          </Link>
                          <Link href="/survey">
                            <a>Surveys</a>
                          </Link>
                        </div>
                      </div>
                    </li>
                  </>
                )}

                {/* {session.role !== "Data-Entry-Officer" && (
                  <>
                    {
                      <li
                        className={`nav-item ${
                          (router.asPath.includes("add-canvass") ||
                            router.asPath.includes("canvass") ||
                            router.asPath.includes("survey")) &&
                          "active"
                        }`}
                      >
                        <div className="dropdown">
                          <Link href="/canvass">
                            <a className="nav-link">Canvass</a>
                          </Link>

                          <div className="dropdown-content">
                            <Link href="/survey">
                              <a>Surveys</a>
                            </Link>
                          </div>
                        </div>
                      </li>
                    }
                  </>
                )} */}

                {router.asPath.includes("messages") ? (
                  <li className="nav-item active">
                    <div className="dropdown">
                      <Link href="/messages">
                        <a className="nav-link">Messages</a>
                      </Link>

                      <div className="dropdown-content">
                        <Link href="/messages/replies">
                          <a>Replies</a>
                        </Link>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item">
                    <div className="dropdown">
                      <Link href="/messages">
                        <a className="nav-link">Messages</a>
                      </Link>

                      <div className="dropdown-content">
                        <Link href="/messages/replies">
                          <a>Replies</a>
                        </Link>
                      </div>
                    </div>
                  </li>
                )}
                {session.role !== "Data-Entry-Officer" &&
                  session.role !== "Manager" && (
                    <>
                      {router.asPath.includes("money") ? (
                        <li className="nav-item active">
                          <div className="dropdown">
                            <Link href="/money">
                              <a className="nav-link">Money</a>
                            </Link>

                            <div className="dropdown-content">
                              <Link href="/money/approvals">
                                <a>Approvals</a>
                              </Link>
                              <Link href="/money/send-money">
                                <a>Send Money</a>
                              </Link>
                              <Link href="/money/donations">
                                <a>Donations</a>
                              </Link>
                              <Link href="/money/campaign-donations">
                                <a>Campaign Donations</a>
                              </Link>
                              <Link href="/money/adopted-areas">
                                <a>Adopted Areas</a>
                              </Link>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <li className="nav-item">
                          <div className="dropdown">
                            <Link href="/money">
                              <a className="nav-link">Money</a>
                            </Link>

                            <div className="dropdown-content">
                              <Link href="/money/approvals">
                                <a>Approvals</a>
                              </Link>
                              <Link href="/money/send-money">
                                <a>Send Money</a>
                              </Link>
                              <Link href="/money/donations">
                                <a>Donations</a>
                              </Link>
                              <Link href="/money/campaign-donations">
                                <a>Campaign Donations</a>
                              </Link>
                              <Link href="/money/adopted-areas">
                                <a>Adopted Areas</a>
                              </Link>
                            </div>
                          </div>
                        </li>
                      )}
                    </>
                  )}
                  <li className="nav-item">
                    <div className="dropdown">
                        <a href="https://volunteers.dmb2024.com/" target="_blank" className="nav-link">
                          Volunteers
                          <sup>
                            <Badge 
                              style={{
                                background: "#2265D1",
                                color: "#fff",
                                fontSize: "0.7rem",
                                padding: "0.2rem 0.2rem",
                                borderRadius: "5px"
                              }} 
                              className="ml-1"
                            >
                              New
                            </Badge>
                          </sup>
                        </a>
                    </div>
                  </li>
                <li className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link p-0"
                    id="navbarProfileDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="d-none d-lg-block">
                      <img
                        src={session.user.image}
                        width="36"
                        height="36"
                        alt="user"
                        className="rounded-circle object-fit-cover"
                      />
                    </span>
                    <span className="d-block d-lg-none">Account</span>
                  </a>

                  <div
                    className="dropdown-menu m-0 dropdown-menu-right border-0"
                    aria-labelledby="navbarProfileDropdown"
                  >
                    {session.role === "Administrator" && (
                      <>
                        <Link href="/admin">
                          <a className="dropdown-item">Manage Users</a>
                        </Link>
                      </>
                    )}
                    <Link href="/api/auth/signout">
                      <a className="dropdown-item">Logout</a>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
